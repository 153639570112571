// @flow
const ICONS = {
  TWITTER: {
    path: 'M25.312 6.375c-0.688 1-1.547 1.891-2.531 2.609 0.016 0.219 0.016 0.438 0.016 0.656 0 6.672-5.078 14.359-14.359 14.359-2.859 0-5.516-0.828-7.75-2.266 0.406 0.047 0.797 0.063 1.219 0.063 2.359 0 4.531-0.797 6.266-2.156-2.219-0.047-4.078-1.5-4.719-3.5 0.313 0.047 0.625 0.078 0.953 0.078 0.453 0 0.906-0.063 1.328-0.172-2.312-0.469-4.047-2.5-4.047-4.953v-0.063c0.672 0.375 1.453 0.609 2.281 0.641-1.359-0.906-2.25-2.453-2.25-4.203 0-0.938 0.25-1.797 0.688-2.547 2.484 3.062 6.219 5.063 10.406 5.281-0.078-0.375-0.125-0.766-0.125-1.156 0-2.781 2.25-5.047 5.047-5.047 1.453 0 2.766 0.609 3.687 1.594 1.141-0.219 2.234-0.641 3.203-1.219-0.375 1.172-1.172 2.156-2.219 2.781 1.016-0.109 2-0.391 2.906-0.781z',
    viewBox: '0 0 26 28'
  },
  GITHUB: {
    path: 'M10 19c0 1.141-0.594 3-2 3s-2-1.859-2-3 0.594-3 2-3 2 1.859 2 3zM20 19c0 1.141-0.594 3-2 3s-2-1.859-2-3 0.594-3 2-3 2 1.859 2 3zM22.5 19c0-2.391-1.453-4.5-4-4.5-1.031 0-2.016 0.187-3.047 0.328-0.812 0.125-1.625 0.172-2.453 0.172s-1.641-0.047-2.453-0.172c-1.016-0.141-2.016-0.328-3.047-0.328-2.547 0-4 2.109-4 4.5 0 4.781 4.375 5.516 8.188 5.516h2.625c3.813 0 8.188-0.734 8.188-5.516zM26 16.25c0 1.734-0.172 3.578-0.953 5.172-2.063 4.172-7.734 4.578-11.797 4.578-4.125 0-10.141-0.359-12.281-4.578-0.797-1.578-0.969-3.437-0.969-5.172 0-2.281 0.625-4.438 2.125-6.188-0.281-0.859-0.422-1.766-0.422-2.656 0-1.172 0.266-2.344 0.797-3.406 2.469 0 4.047 1.078 5.922 2.547 1.578-0.375 3.203-0.547 4.828-0.547 1.469 0 2.953 0.156 4.375 0.5 1.859-1.453 3.437-2.5 5.875-2.5 0.531 1.062 0.797 2.234 0.797 3.406 0 0.891-0.141 1.781-0.422 2.625 1.5 1.766 2.125 3.938 2.125 6.219z',
    viewBox: '0 0 26 28'
  },
  EMAIL: {
    path: 'M26 23.5v-12c-0.328 0.375-0.688 0.719-1.078 1.031-2.234 1.719-4.484 3.469-6.656 5.281-1.172 0.984-2.625 2.188-4.25 2.188h-0.031c-1.625 0-3.078-1.203-4.25-2.188-2.172-1.813-4.422-3.563-6.656-5.281-0.391-0.313-0.75-0.656-1.078-1.031v12c0 0.266 0.234 0.5 0.5 0.5h23c0.266 0 0.5-0.234 0.5-0.5zM26 7.078c0-0.391 0.094-1.078-0.5-1.078h-23c-0.266 0-0.5 0.234-0.5 0.5 0 1.781 0.891 3.328 2.297 4.438 2.094 1.641 4.188 3.297 6.266 4.953 0.828 0.672 2.328 2.109 3.422 2.109h0.031c1.094 0 2.594-1.437 3.422-2.109 2.078-1.656 4.172-3.313 6.266-4.953 1.016-0.797 2.297-2.531 2.297-3.859zM28 6.5v17c0 1.375-1.125 2.5-2.5 2.5h-23c-1.375 0-2.5-1.125-2.5-2.5v-17c0-1.375 1.125-2.5 2.5-2.5h23c1.375 0 2.5 1.125 2.5 2.5z',
    viewBox: '0 0 28 28'
  },
  LINKEDIN: {
    path: 'M26.49 0.05L26.63 0.09L26.77 0.14L26.9 0.2L27.03 0.27L27.15 0.35L27.27 0.43L27.37 0.52L27.48 0.63L27.57 0.73L27.65 0.85L27.73 0.97L27.8 1.1L27.86 1.23L27.91 1.37L27.95 1.51L27.98 1.66L27.99 1.81L28 1.96L28 26.02L27.99 26.17L27.98 26.32L27.95 26.47L27.91 26.62L27.86 26.75L27.8 26.89L27.73 27.02L27.65 27.14L27.56 27.26L27.47 27.37L27.37 27.47L27.26 27.56L27.14 27.65L27.02 27.73L26.89 27.8L26.75 27.86L26.62 27.91L26.47 27.95L26.32 27.98L26.17 27.99L26.02 28L2.09 28L1.93 27.99L1.77 27.98L1.61 27.94L1.46 27.9L1.31 27.85L1.17 27.79L1.03 27.71L0.91 27.63L0.78 27.54L0.67 27.44L0.56 27.33L0.46 27.22L0.37 27.09L0.29 26.97L0.21 26.83L0.15 26.69L0.1 26.54L0.06 26.39L0.02 26.23L0.01 26.07L0 25.91L0 2.1L0.01 1.93L0.02 1.77L0.06 1.62L0.1 1.46L0.15 1.32L0.21 1.17L0.29 1.04L0.37 0.91L0.46 0.78L0.56 0.67L0.67 0.56L0.78 0.46L0.91 0.37L1.04 0.29L1.17 0.21L1.32 0.15L1.46 0.1L1.62 0.06L1.77 0.02L1.93 0.01L2.1 0L26.04 0L26.19 0.01L26.34 0.02L26.34 0.02L26.49 0.05ZM8.36 23.94L8.36 10.56L4.16 10.56L4.16 23.94L4.16 23.94L8.36 23.94ZM17.19 10.48L16.57 10.8L16.01 11.23L15.48 11.76L15.01 12.4L15.01 12.33L15.01 10.56L10.97 10.56L10.97 23.94L15.17 23.94L15.15 17.34L15.15 16.69L15.22 16.07L15.34 15.53L15.52 15.07L15.75 14.68L16.05 14.37L16.4 14.14L16.81 13.98L17.28 13.91L17.96 13.92L18.5 14.05L18.92 14.29L19.24 14.6L19.46 14.97L19.61 15.37L19.71 15.78L19.75 16.18L19.77 16.55L19.77 16.85L19.77 17.28L19.78 17.83L19.78 18.52L19.78 19.34L19.78 20.3L19.77 21.38L19.77 22.59L19.77 23.94L23.99 23.94L23.99 16.55L23.96 15.47L23.86 14.5L23.68 13.62L23.42 12.84L23.08 12.17L22.66 11.59L22.17 11.12L21.6 10.74L20.95 10.47L20.11 10.26L19.31 10.15L18.55 10.16L17.85 10.26L17.85 10.26L17.19 10.48ZM4.02 5.33L3.83 6.28L4.02 7.23L4.54 8L5.32 8.52L6.26 8.71L7.21 8.52L7.98 8L8.51 7.23L8.7 6.28L8.51 5.33L7.98 4.56L7.21 4.03L6.26 3.84L5.32 4.03L4.54 4.56L4.54 4.56L4.02 5.33Z',
    viewBox: '0 0 28 28'
  },
  RSS: {
    path: 'M6 21c0 1.656-1.344 3-3 3s-3-1.344-3-3 1.344-3 3-3 3 1.344 3 3zM14 22.922c0.016 0.281-0.078 0.547-0.266 0.75-0.187 0.219-0.453 0.328-0.734 0.328h-2.109c-0.516 0-0.938-0.391-0.984-0.906-0.453-4.766-4.234-8.547-9-9-0.516-0.047-0.906-0.469-0.906-0.984v-2.109c0-0.281 0.109-0.547 0.328-0.734 0.172-0.172 0.422-0.266 0.672-0.266h0.078c3.328 0.266 6.469 1.719 8.828 4.094 2.375 2.359 3.828 5.5 4.094 8.828zM22 22.953c0.016 0.266-0.078 0.531-0.281 0.734-0.187 0.203-0.438 0.313-0.719 0.313h-2.234c-0.531 0-0.969-0.406-1-0.938-0.516-9.078-7.75-16.312-16.828-16.844-0.531-0.031-0.938-0.469-0.938-0.984v-2.234c0-0.281 0.109-0.531 0.313-0.719 0.187-0.187 0.438-0.281 0.688-0.281h0.047c5.469 0.281 10.609 2.578 14.484 6.469 3.891 3.875 6.188 9.016 6.469 14.484z',
    viewBox: '0 0 22 28'
  }
};

export default ICONS;
